import React from 'react';
import Logos from "../components/Logos";
import {useDispatch} from "react-redux";
import {setActivePanel} from "../store/mainReducer";

const Menu = () => {
    const dispatch = useDispatch()

    return (
        <div className="container menu-page page">
            <a href="#" className="menu-page__logo logo link_disabled">
                <img width="321" height="145" src="images/logo-color.png" alt=""/>
            </a>
            <div className="menu-page__buttons">
                <button onClick={()=>dispatch(setActivePanel('tasks'))} className="menu-button menu-button_type_tasks">Задания</button>
                <button onClick={()=>dispatch(setActivePanel('links'))} className="menu-button menu-button_type_links">Трансляции</button>
            </div>
            <Logos/>
        </div>
    );
};

export default Menu;