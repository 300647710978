import React from 'react';
import {useDispatch} from "react-redux";
import {setActivePanel} from "../store/mainReducer";

const BackBtn = () => {
    const dispatch = useDispatch()
    return (
        <button onClick={()=>dispatch(setActivePanel('menu'))} className="menu-header__back-link">
            <img width="23" height="22" src="images/back-arrow.svg" alt=""/>
            <span>назад</span>
        </button>
    );
};

export default BackBtn;