import React from 'react';
import BackBtn from "../components/BackBtn";
import LinksList from "../components/LinksList";
import Logos from "../components/Logos";

const Links = () => {
    return (
        <div className="container links-page page">
            <div className="links-page__menu-header menu-header">
                <BackBtn/>
                <p className="menu-header__caption">Трансляции</p>
            </div>
            <div className="links-list">
                <LinksList/>
            </div>
        <Logos/>
        </div>
    );
};

export default Links;