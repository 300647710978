import {createSlice} from "@reduxjs/toolkit";

const mainSlice = createSlice({
    name: 'main',
    initialState: {
        activePanel: 'init',
        user: null,
        task: 1
    },
    reducers: {
        setActivePanel(state, action) {
            state.activePanel = action.payload
        },
        setUser(state, action) {
            state.user = action.payload
        },
        setTask(state, action) {
            state.task = action.payload
        }
    }
})

export default mainSlice.reducer
export const {setActivePanel, setUser, setTask} = mainSlice.actions
