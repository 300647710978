import React from 'react';

const TaskItem = ({id, link, header, desc}) => {

    return (
            <div className="task-item" style={{backgroundImage: `url(images/task${id}.jpg)`}}>
                <p className="task-item__label"><span>{header}</span></p>
                <p className="task-item__caption">{desc}</p>
                <a href={link} target="_blank" className="task-item__button">Выполнить</a>
            </div>
    );
};

export default TaskItem;