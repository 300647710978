import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {setActivePanel} from "../store/mainReducer";

const Init = () => {

    const [progress, setProgress] = useState('progress-10');
    const dispatch = useDispatch()

    useEffect(()=>{
        setProgress('progress-100')
        setTimeout(()=>{
            dispatch(setActivePanel('menu'))
        },3000)
    },[progress])

    return (
        <div className="loading-page container">
            <a href="#" className="loading-page__big-logo big-logo link_disabled">
                <img width="405" height="181" src="images/logo-white.png" alt=""/>
            </a>
            <div className="progress-bar loading-page__progress-bar">
                <div className={`progress-bar__active-line ${progress}`}/>
            </div>
        </div>
    );
};

export default Init;