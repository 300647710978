import React from 'react';
import LinkItem from "./LinkItem";

const LinksList = () => {

    const streams = [
        // 17
        {
            name: "XZelot",
            link: "https://vk.cc/cbaaeG",
            img: '/avatars/XZelot.png',
            date_st: '17',
            hour_st: '12',
            min_st: '00',
            date_end: '17',
            hour_end: '17',
            min_end: '00'
        },
        {
            name: "JoSmog", link: "https://vk.cc/cbaak8", img: '/avatars/JoSmog.png',
            date_st: '17', hour_st: '19', min_st: '00',
            date_end: '18', hour_end: '00', min_end: '00'
        },
        {
            name: "Clorka", link: "https://vk.cc/cbaanN", img: '/avatars/Clorka.png',
            date_st: '17', hour_st: '14', min_st: '00',
            date_end: '17', hour_end: '19', min_end: '00'
        },
        {
            name: "E1", link: "https://vk.cc/cbaapE", img: '/avatars/E1tft.png',
            date_st: '17', hour_st: '13', min_st: '00',
            date_end: '17', hour_end: '18', min_end: '00'
        },
        {
            name: "Goshayy", link: "https://vk.cc/cbaasG", img: '/avatars/Goshayy.png',
            date_st: '17', hour_st: '22', min_st: '00',
            date_end: '18', hour_end: '04', min_end: '00'
        },
        {
            name: "sad_slippers_", link: "https://vk.cc/cbaatW", img: '/avatars/sad_slippers_.png',
            date_st: '17', hour_st: '21', min_st: '00',
            date_end: '18', hour_end: '03', min_end: '00'
        },
        {
            name: "Kapibaro_", link: "https://vk.cc/cbaavu", img: '/avatars/Kapibaro_.png',
            date_st: '17', hour_st: '16', min_st: '00',
            date_end: '17', hour_end: '23', min_end: '00'
        },
        {
            name: "SnowManul", link: "https://vk.cc/cbaaxe", img: '/avatars/SnowManul.png',
            date_st: '17', hour_st: '11', min_st: '00',
            date_end: '17', hour_end: '21', min_end: '00'
        },


        // 18
        {
            name: "XZelot",
            link: "https://vk.cc/cbaaeG",
            img: '/avatars/XZelot.png',
            date_st: '18',
            hour_st: '12',
            min_st: '00',
            date_end: '18',
            hour_end: '17',
            min_end: '00'
        },
        {
            name: "JoSmog", link: "https://vk.cc/cbaak8", img: '/avatars/JoSmog.png',
            date_st: '18', hour_st: '19', min_st: '00',
            date_end: '19', hour_end: '00', min_end: '00'
        },
        {
            name: "Clorka", link: "https://vk.cc/cbaanN", img: '/avatars/Clorka.png',
            date_st: '18', hour_st: '17', min_st: '00',
            date_end: '19', hour_end: '00', min_end: '00'
        },
        {
            name: "Goshayy", link: "https://vk.cc/cbaasG", img: '/avatars/Goshayy.png',
            date_st: '18', hour_st: '22', min_st: '00',
            date_end: '19', hour_end: '04', min_end: '00'
        },
        {
            name: "sad_slippers_", link: "https://vk.cc/cbaatW", img: '/avatars/sad_slippers_.png',
            date_st: '18', hour_st: '21', min_st: '00',
            date_end: '19', hour_end: '03', min_end: '00'
        },
        {
            name: "Kapibaro_", link: "https://vk.cc/cbaavu", img: '/avatars/Kapibaro_.png',
            date_st: '18', hour_st: '16', min_st: '00',
            date_end: '18', hour_end: '23', min_end: '00'
        },
        {
            name: "SnowManul", link: "https://vk.cc/cbaaxe", img: '/avatars/SnowManul.png',
            date_st: '18', hour_st: '11', min_st: '00',
            date_end: '18', hour_end: '21', min_end: '00'
        },
        {
            name: "E1", link: "https://vk.cc/cbaapE", img: '/avatars/E1tft.png',
            date_st: '18', hour_st: '13', min_st: '00',
            date_end: '18', hour_end: '18', min_end: '00'
        },
        {
            name: "StrongEstet", link: "https://vk.cc/cbaT5b", img: '/avatars/StrongEstet.png',
            date_st: '18', hour_st: '12', min_st: '00',
            date_end: '18', hour_end: '21', min_end: '00'
        },
        // 19
        {
            name: "XZelot",
            link: "https://vk.cc/cbaaeG",
            img: '/avatars/XZelot.png',
            date_st: '19',
            hour_st: '12',
            min_st: '00',
            date_end: '19',
            hour_end: '17',
            min_end: '00'
        },
        {
            name: "JoSmog", link: "https://vk.cc/cbaak8", img: '/avatars/JoSmog.png',
            date_st: '19', hour_st: '19', min_st: '00',
            date_end: '20', hour_end: '00', min_end: '00'
        },
        {
            name: "Clorka", link: "https://vk.cc/cbaanN", img: '/avatars/Clorka.png',
            date_st: '19', hour_st: '17', min_st: '00',
            date_end: '20', hour_end: '00', min_end: '00'
        },
        {
            name: "E1", link: "https://vk.cc/cbaapE", img: '/avatars/E1tft.png',
            date_st: '19', hour_st: '10', min_st: '00',
            date_end: '19', hour_end: '18', min_end: '00'
        },
        {
            name: "Goshayy", link: "https://vk.cc/cbaasG", img: '/avatars/Goshayy.png',
            date_st: '19', hour_st: '22', min_st: '00',
            date_end: '20', hour_end: '04', min_end: '00'
        },
        {
            name: "sad_slippers_", link: "https://vk.cc/cbaatW", img: '/avatars/sad_slippers_.png',
            date_st: '19', hour_st: '21', min_st: '00',
            date_end: '20', hour_end: '03', min_end: '00'
        },
        {
            name: "Kapibaro_", link: "https://vk.cc/cbaavu", img: '/avatars/Kapibaro_.png',
            date_st: '19', hour_st: '14', min_st: '00',
            date_end: '19', hour_end: '23', min_end: '00'
        },
        {
            name: "SnowManul", link: "https://vk.cc/cbaaxe", img: '/avatars/SnowManul.png',
            date_st: '19', hour_st: '11', min_st: '00',
            date_end: '19', hour_end: '21', min_end: '00'
        },
        {
            name: "StrongEstet", link: "https://vk.cc/cbaT5b", img: '/avatars/StrongEstet.png',
            date_st: '19', hour_st: '12', min_st: '00',
            date_end: '19', hour_end: '21', min_end: '00'
        },


        // 20
        {
            name: "XZelot",
            link: "https://vk.cc/cbaaeG",
            img: '/avatars/XZelot.png',
            date_st: '20',
            hour_st: '12',
            min_st: '00',
            date_end: '20',
            hour_end: '17',
            min_end: '00'
        },
        {
            name: "JoSmog", link: "https://vk.cc/cbaak8", img: '/avatars/JoSmog.png',
            date_st: '20', hour_st: '19', min_st: '00',
            date_end: '21', hour_end: '00', min_end: '00'
        },
        {
            name: "Clorka", link: "https://vk.cc/cbaanN", img: '/avatars/Clorka.png',
            date_st: '20', hour_st: '17', min_st: '00',
            date_end: '21', hour_end: '00', min_end: '00'
        },
        {
            name: "E1", link: "https://vk.cc/cbaapE", img: '/avatars/E1tft.png',
            date_st: '20', hour_st: '10', min_st: '00',
            date_end: '20', hour_end: '18', min_end: '00'
        },
        {
            name: "Goshayy", link: "https://vk.cc/cbaasG", img: '/avatars/Goshayy.png',
            date_st: '20', hour_st: '22', min_st: '00',
            date_end: '21', hour_end: '04', min_end: '00'
        },
        {
            name: "sad_slippers_", link: "https://vk.cc/cbaatW", img: '/avatars/sad_slippers_.png',
            date_st: '20', hour_st: '21', min_st: '00',
            date_end: '21', hour_end: '03', min_end: '00'
        },
        {
            name: "Kapibaro_", link: "https://vk.cc/cbaavu", img: '/avatars/Kapibaro_.png',
            date_st: '20', hour_st: '16', min_st: '00',
            date_end: '20', hour_end: '23', min_end: '00'
        },
        {
            name: "SnowManul", link: "https://vk.cc/cbaaxe", img: '/avatars/SnowManul.png',
            date_st: '20', hour_st: '11', min_st: '00',
            date_end: '20', hour_end: '21', min_end: '00'
        },


        //21
        {
            name: "XZelot",
            link: "https://vk.cc/cbaaeG",
            img: '/avatars/XZelot.png',
            date_st: '21',
            hour_st: '12',
            min_st: '00',
            date_end: '21',
            hour_end: '17',
            min_end: '00'
        },
        {
            name: "JoSmog", link: "https://vk.cc/cbaak8", img: '/avatars/JoSmog.png',
            date_st: '21', hour_st: '19', min_st: '00',
            date_end: '22', hour_end: '00', min_end: '00'
        },
        {
            name: "Clorka", link: "https://vk.cc/cbaanN", img: '/avatars/Clorka.png',
            date_st: '21', hour_st: '17', min_st: '00',
            date_end: '22', hour_end: '00', min_end: '00'
        },
        {
            name: "E1", link: "https://vk.cc/cbaapE", img: '/avatars/E1tft.png',
            date_st: '21', hour_st: '13', min_st: '00',
            date_end: '21', hour_end: '18', min_end: '00'
        },
        {
            name: "Goshayy", link: "https://vk.cc/cbaasG", img: '/avatars/Goshayy.png',
            date_st: '21', hour_st: '22', min_st: '00',
            date_end: '22', hour_end: '04', min_end: '00'
        },
        {
            name: "sad_slippers_", link: "https://vk.cc/cbaatW", img: '/avatars/sad_slippers_.png',
            date_st: '21', hour_st: '21', min_st: '00',
            date_end: '22', hour_end: '03', min_end: '00'
        },
        {
            name: "Kapibaro_", link: "https://vk.cc/cbaavu", img: '/avatars/Kapibaro_.png',
            date_st: '21', hour_st: '16', min_st: '00',
            date_end: '21', hour_end: '23', min_end: '00'
        },
        {
            name: "SnowManul", link: "https://vk.cc/cbaaxe", img: '/avatars/SnowManul.png',
            date_st: '21', hour_st: '11', min_st: '00',
            date_end: '21', hour_end: '21', min_end: '00'
        },
        {
            name: "StrongEstet", link: "https://vk.cc/cbaT5b", img: '/avatars/StrongEstet.png',
            date_st: '21', hour_st: '12', min_st: '00',
            date_end: '21', hour_end: '21', min_end: '00'
        },

        //22
        {
            name: "XZelot",
            link: "https://vk.cc/cbaaeG",
            img: '/avatars/XZelot.png',
            date_st: '22',
            hour_st: '12',
            min_st: '00',
            date_end: '22',
            hour_end: '17',
            min_end: '00'
        },
        {
            name: "JoSmog", link: "https://vk.cc/cbaak8", img: '/avatars/JoSmog.png',
            date_st: '22', hour_st: '19', min_st: '00',
            date_end: '23', hour_end: '00', min_end: '00'
        },
        {
            name: "Clorka", link: "https://vk.cc/cbaanN", img: '/avatars/Clorka.png',
            date_st: '22', hour_st: '17', min_st: '00',
            date_end: '23', hour_end: '00', min_end: '00'
        },
        {
            name: "E1", link: "https://vk.cc/cbaapE", img: '/avatars/E1tft.png',
            date_st: '22', hour_st: '13', min_st: '00',
            date_end: '22', hour_end: '18', min_end: '00'
        },
        {
            name: "sad_slippers_", link: "https://vk.cc/cbaatW", img: '/avatars/sad_slippers_.png',
            date_st: '22', hour_st: '21', min_st: '00',
            date_end: '23', hour_end: '03', min_end: '00'
        },
        {
            name: "Kapibaro_", link: "https://vk.cc/cbaavu", img: '/avatars/Kapibaro_.png',
            date_st: '22', hour_st: '16', min_st: '00',
            date_end: '22', hour_end: '23', min_end: '00'
        },
        {
            name: "SnowManul", link: "https://vk.cc/cbaaxe", img: '/avatars/SnowManul.png',
            date_st: '22', hour_st: '11', min_st: '00',
            date_end: '22', hour_end: '21', min_end: '00'
        },
        {
            name: "StrongEstet", link: "https://vk.cc/cbaT5b", img: '/avatars/StrongEstet.png',
            date_st: '22', hour_st: '12', min_st: '00',
            date_end: '22', hour_end: '21', min_end: '00'
        },
        //23
        {
            name: "XZelot",
            link: "https://vk.cc/cbaaeG",
            img: '/avatars/XZelot.png',
            date_st: '23',
            hour_st: '12',
            min_st: '00',
            date_end: '23',
            hour_end: '17',
            min_end: '00'
        },
        {
            name: "JoSmog", link: "https://vk.cc/cbaak8", img: '/avatars/JoSmog.png',
            date_st: '23', hour_st: '19', min_st: '00',
            date_end: '24', hour_end: '00', min_end: '00'
        },
        {
            name: "Clorka", link: "https://vk.cc/cbaanN", img: '/avatars/Clorka.png',
            date_st: '23', hour_st: '17', min_st: '00',
            date_end: '24', hour_end: '00', min_end: '00'
        },
        {
            name: "E1", link: "https://vk.cc/cbaapE", img: '/avatars/E1tft.png',
            date_st: '23', hour_st: '13', min_st: '00',
            date_end: '23', hour_end: '18', min_end: '00'
        },
        {
            name: "sad_slippers_", link: "https://vk.cc/cbaatW", img: '/avatars/sad_slippers_.png',
            date_st: '23', hour_st: '21', min_st: '00',
            date_end: '24', hour_end: '03', min_end: '00'
        },
        {
            name: "Kapibaro_", link: "https://vk.cc/cbaavu", img: '/avatars/Kapibaro_.png',
            date_st: '23', hour_st: '16', min_st: '00',
            date_end: '23', hour_end: '23', min_end: '00'
        },
        {
            name: "SnowManul", link: "https://vk.cc/cbaaxe", img: '/avatars/SnowManul.png',
            date_st: '23', hour_st: '11', min_st: '00',
            date_end: '23', hour_end: '21', min_end: '00'
        },
        {
            name: "Goshayy", link: "https://vk.cc/cbaasG", img: '/avatars/Goshayy.png',
            date_st: '23', hour_st: '22', min_st: '00',
            date_end: '24', hour_end: '04', min_end: '00'
        },
        {
            name: "StrongEstet", link: "https://vk.cc/cbaT5b", img: '/avatars/StrongEstet.png',
            date_st: '23', hour_st: '12', min_st: '00',
            date_end: '23', hour_end: '21', min_end: '00'
        },
    ]
    streams.sort(function (a, b) {
        let first = Date.parse(`2022-02-${a.date_st}T${a.hour_st}:${a.min_st}:00.000+03:00`)
        let second = Date.parse(`2022-02-${b.date_st}T${b.hour_st}:${b.min_st}:00.000+03:00`)
        return first - second
    })

    let content = []

    for (const stream in streams) {
        let end = Date.parse(`2022-02-${streams[stream].date_st}T${streams[stream].hour_st}:${streams[stream].min_st}:00.000+03:00`)
        let now = new Date()
        if (now.getUTCHours() < 7) {
            if (now.getUTCDate() - 1 == new Date(end).getUTCDate()) {
                content.push(<LinkItem name={streams[stream].name}
                                       link={streams[stream].link}
                                       img={streams[stream].img}
                                       time={`${streams[stream].date_st}.02 / ${streams[stream].hour_st}:${streams[stream].min_st} MCK`}/>)
            }
        } else {
            if (now.getUTCDate()  == new Date(end).getUTCDate()) {
                content.push(<LinkItem name={streams[stream].name}
                                       link={streams[stream].link}
                                       img={streams[stream].img}
                                       time={`${streams[stream].date_st}.02 / ${streams[stream].hour_st}:${streams[stream].min_st} MCK`}/>)
            }
        }
    }

    return (
        <>
            {content}
        </>
    );
};

export default LinksList;