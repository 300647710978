import React from 'react';
import TaskItem from "./TaskItem";

const TasksList = () => {

    const tasks = {
        1: {
            id:1,
            link: 'https://vk.com/wall-189276188_67197',
            header:"Задание 1",
            desc: <>Приветствуем<br/>тактиков</>,
        },
        2: {
            id:2,
            link: 'https://vk.com/wall-189276188_67198',
            header:"Задание 2",
            desc: <>Минутка<br/>теории</>,
        },
        3: {
            id:3,
            link: 'https://vk.com/wall-189276188_67199',
            header:"Задание 3",
            desc: <>Попробуйте<br/>сами</>,
        },
        4: {
            id:4,
            link: 'https://vk.com/wall-189276188_67200',
            header:"Задание 4",
            desc: <>Отыщите<br/>код</>,
        },
        5: {
            id:5,
            link: 'https://vk.com/wall-189276188_67201',
            header:"Задание 5",
            desc: <>Глубиния заходит<br/>в гости</>,
        },
        6: {
            id:6,
            link: 'https://vk.com/wall-189276188_67202',
            header:"Задание 6",
            desc: <>Учитесь<br/>у лучших</>,
        },
        7: {
            id:7,
            link: 'https://vk.com/wall-189276188_67203',
            header:"Задание 7",
            desc: <>Удар<br/>для двоих</>,
        },
    }

    let content = []

    for (const task in tasks) {
        content.push(<TaskItem id={tasks[task].id} link={tasks[task].link} desc={tasks[task].desc} header={tasks[task].header}/>)
    }

    return (
        <>
            {content}
        </>
    );
};

export default TasksList;