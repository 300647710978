import React from 'react';
import {useSelector} from "react-redux";
import Init from "./panels/Init";
import Menu from "./panels/Menu";
import Links from "./panels/Links";
import Tasks from "./panels/Tasks";



const App = () => {

  const activePanel = useSelector(state => state.main.activePanel)


  let content = []
  if (activePanel === 'init') {
    content.push(<Init/>)
  } else if (activePanel === 'menu') {
    content.push(<Menu/>)
  } else if (activePanel === 'links') {
    content.push(<Links/>)
  } else if (activePanel === 'tasks') {
    content.push(<Tasks/>)
  }

  return (
      <div className='app'>
        {content}
      </div>
  );
}
export default App;

