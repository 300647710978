import React from 'react';

const LinkItem = ({link, name, time, img}) => {
    return (
        <div className="link-item">
            <div className="link-item__image-wrapper">
                <img src={`images/${img}`} width="151" height="151" alt=""/>
            </div>
            <div className="link-item__info">
                <p className="link-item__caption">{name}</p>
                <p className="link-item__time">
                    Начало<br/>
                    {time}
                </p>
            </div>
            <a href={link} target="_blank" className="link-item__button">
                Смотреть
            </a>
        </div>
    );
};

export default LinkItem;