import React from 'react';
import Logos from "../components/Logos";
import BackBtn from "../components/BackBtn";
import TasksList from "../components/TasksList";

const Tasks = () => {
    return (
        <div className="container tasks-page page">
            <div className="tasks-page__menu-header menu-header">
                <BackBtn/>
                <p className="menu-header__caption">задания</p>
            </div>
            <div className="tasks-list">
                <TasksList/>
            </div>
            <Logos/>
        </div>
    );
};

export default Tasks;