import React from 'react';

const Logos = () => {
    return (
        <div className="container__logos">
            <img className="logo-riot" src="images/logo-riot.png" width="120" height="40" alt=""/>
            <img className="age-icon" src="images/age-icon.png" width="53" height="60" alt=""/>
            <img className="logo-white" src="images/logo-white.png" width="105" height="47" alt=""/>
        </div>
    );
};

export default Logos;